// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
require('jquery')
import "channels"
import "cocoon";
import "chartkick/chart.js"
Rails.start()
ActiveStorage.start()

import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap'
import '../stylesheets/application'
import 'datatables.net-buttons/js/buttons.html5.min.js'
import "@fortawesome/fontawesome-free/js/all"

require("stylesheets/application")
require('datatables.net-bs4')
require('datatables.net-buttons')
require('datatables.net-buttons/js/buttons.colVis.js')
require('datatables.net-buttons/js/buttons.html5.min.js')
require('datatables.net-fixedcolumns-bs4')
import $ from 'jquery';

$(function () {


  $(".datatable").DataTable({
    "bPaginate": false,
    'aLengthMenu' : [
      [10, 25, 50, 100, 200, -1],
      [10, 25, 50, 100, 200, 'All']
    ],
    // 'pageLength': 25,
    'iDisplayLength' : -1,
    'language' : {
      'lengthMenu' : 'Showing _MENU_ records per page',
    },
    "fixedHeader" : {
      "header": true,
      "headerOffset": 50
    },
    "bInfo": false,
    "bSort": true,
    "columnDefs": [ {
      "bSortable" : false,
      "targets" : [ "sorting_disabled" ]
    } ],
    dom: 'Bfrtip',
    buttons: [
      {
        extend: 'csvHtml5',
        text: 'Export',
        className: 'mt-15',
        filename: 'export'
      }
    ]
  });

  $(".datatable-no-sort").DataTable({
    "bPaginate": false,
    'aLengthMenu' : [
      [10, 25, 50, 100, 200, -1],
      [10, 25, 50, 100, 200, 'All']
    ],
    // 'pageLength': 25,
    'iDisplayLength' : -1,
    'language' : {
      'lengthMenu' : 'Showing _MENU_ records per page',
    },
    "fixedHeader" : {
      "header": true,
      "headerOffset": 50
    },
    "bInfo": false,
    "bSort": false,
    "columnDefs": [ {
      "bSortable" : false,
      "targets" : [ "sorting_disabled" ]
    } ],
    dom: 'Bfrtip',
    buttons: [
      {
        extend: 'csvHtml5',
        text: 'Export',
        className: 'mt-15',
        filename: 'export'
      }
    ]
  });

  $(".datatable-fixed-columns").DataTable({
    "dom" : "Bfrtip",
    "scrollX" : true,
    "paging" : false,
    "scrollCollapse" : true,
    "buttons" : [ {
      "extend" : "colvis",
      "text" : "Select Categories",
      "columns" : ":not(:first-child)"
    },
    {
      extend: 'csvHtml5',
      text: 'Export',
      className: 'mt-15',
      filename: 'export'
    }],
    "fixedColumns" : {
      "left": 1,
    },
  });

});